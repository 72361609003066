import { memo, useState, useEffect, useMemo } from "react";
import { Form, Button, Card, CardBody, Modal, UncontrolledTooltip, ModalFooter, Table, Input, ModalHeader, FormGroup, Label  } from "reactstrap";
import classes from "./styles.module.scss";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "components/Common/CustomInput";
import CustomSelect from "components/Common/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import SearchLocationInput from "components/Common/GoogleAddress";
import {
  PreferenceAvailableTypes,
  PreferencePolicyTypes,
} from "models/Contractor/NewMember/Preference";
import CustomSlider from "components/Common/CustomSlider";
import CustomDatePicker from "components/Common/CustomDatePicker";
import moment from "moment";
import clsx from "clsx";
import { NOTICE_PERIOD, SET_TIME } from "config/constants";
import { UtilContractor } from "utils/contractor";
import { VisaTypes } from "models/Contractor/NewMember/MojCheck";
import { convertVisaType } from "utils/moj";
import { TalentProfileService } from "services/Employer/Listing/Talent/TalentProfile";
import { BasicProfileService } from "services/Employer/NewMember/BasicProfile";
import {
  setErrorMess,
  setLoading,
  setSuccessMess,
} from "redux/reducers/Status/actionTypes";
import DeleteWarningModal from "./PopupDeleteWarning";
import {
  setTalentReducer
} from "redux/reducers/Employer/actionTypes";
import { ReducerType } from "redux/reducers";
import { requestGetTalentList } from "redux/reducers/Employer/actionTypes";

interface ITalent {
  id: number;
  profileId: number;
  firstName: string;
  lastName: string;
  city: string;
  region: string;
  country: string;
  address: string;
  phoneNumber: string;
  title: string;
  email: string;
  authorityToWork: {
    id: number;
    name: string;
    value: string;
  };
  status: string;
  preferenceId: number;
  availableType: {
    id: number;
    name: string;
  };
  noticePeriod: number;
  setTime: Date;
  minHourlyRate: number;
  hourlyRate: number;
  policy: {
    id: number;
    name: string;
    value: string;
  };
  cvUpload: any;
  preferFixedTerm?: boolean;
  preferPermanent?: boolean;
  minSalary?: number;
  maxSalary?: number;
  radius?: number;
}

interface Props {
  onClose: () => void;
  isOpen: boolean;
  itemEdit: any;
  contractorProfile: any; // Add this
  handleAddTalent: (profileData, preferenceData, cvUpload) => void;
  handleUpdateTalent: (profileId, profileData, preferenceData, cvUpload) => void;
}

const AddNewJob = memo(
  ({ onClose, isOpen, itemEdit, contractorProfile, handleAddTalent, handleUpdateTalent }: Props) => {
    const dispatch = useDispatch();
    const { talentsList } = useSelector(
      (state: ReducerType) => state.employer
    );
    const [warningModalOpen, setWarningModalOpen] = useState({
      isOpen: false,
      item: null,
    });
    const [showDetails, setShowDetails] = useState(false); // Add this
    const { setting } = useSelector((state: ReducerType) => state.employer);
    const { user } = useSelector((state: ReducerType) => state.user);
    const schema = useMemo(() => {
      return yup.object().shape({
        id: yup.number(),
        title: yup
          .string()
          .min(2, "At least 2 characters")
          .max(64, "At least 64 characters")
          .required("This field is required"),
        firstName: yup
          .string()
          .min(2, "At least 2 characters")
          .max(64, "At least 64 characters")
          .required("This field is required"),
        lastName: yup
          .string()
          .min(2, "At least 2 characters")
          .max(64, "At least 64 characters")
          .required("This field is required"),
        email: yup
          .string()
          .email("Please enter a valid email")
          .required("Email is required"),
        authorityToWork: yup.object().nullable().required("This field is required"),
        minHourlyRate: yup
          .number()
          .typeError("Please enter a number")
          .min(30, "At least $30")
          .max(300, "At most $300")
          .required("This field is required"),
        hourlyRate: yup
          .number()
          .typeError("Please enter a number")
          .min(30, "At least $30")
          .max(300, "At most $300")
          .required("This field is required"),
        policy: yup.object().nullable().required("This field is required"),
        address: yup.string().required("This field is required"),
        country: yup.string(),
        city: yup.string(),
        region: yup.string(),
        availableType: yup
          .object()
          .nullable()
          .required("Please select your availability"),
        setTime: yup
          .date()
          .nullable()
          .when("availableType", {
            is: (type) => type?.id === SET_TIME,
            then: yup
              .date()
              .typeError("Please enter a valid date")
              .required("This field is required"),
          }),
        cvUpload: yup.mixed().nullable(),
      });
    }, []);

    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
      resetField,
      setValue,
      watch,
      control,
    } = useForm<ITalent>({
      resolver: yupResolver(schema),
      mode: "onChange",
    });

    const availableType = watch("availableType");

    useEffect(() => {
      if (availableType?.id === SET_TIME) {
        resetField("noticePeriod");
        setValue("noticePeriod", null);
      } else if (availableType?.id === NOTICE_PERIOD) {
        resetField("setTime");
        setValue("setTime", null);
      }
    }, [availableType]);

    useEffect(() => {
      if (!isOpen) {
        reset({
          title: "",
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          authorityToWork: null,
          region: "",
          city: "",
          country: "",
          address: "",
          minHourlyRate: 30,
          hourlyRate: 30,
          policy: null,
        });
      } else {
        if (itemEdit && contractorProfile) {
          reset({
            id: itemEdit?.id,
            title: contractorProfile?.profile?.title || "",
            firstName: contractorProfile?.profile?.firstName || "",
            lastName: contractorProfile?.profile?.lastName || "",
            email: itemEdit?.email || "",
            phoneNumber: contractorProfile?.contact?.mobile || "",
            authorityToWork: convertVisaType(contractorProfile?.verification?.visaType),
            address: contractorProfile?.contact?.city && contractorProfile?.contact?.country
              ? contractorProfile?.contact?.region
                ? `${contractorProfile?.contact?.region}, ${contractorProfile?.contact?.city}, ${contractorProfile?.contact?.country}`
                : `${contractorProfile?.contact?.city}, ${contractorProfile?.contact?.country}`
              : "",
            minHourlyRate: contractorProfile?.preference?.minRate || 30,
            hourlyRate: contractorProfile?.preference?.maxRate || 30,
            policy: UtilContractor.getCurrentWorkPolicy(contractorProfile?.preference?.workPolicy),
            setTime: contractorProfile?.preference?.availableDate ? new Date(contractorProfile?.preference?.availableDate) : null,
            availableType: UtilContractor.getCurrentAvailableType(
              contractorProfile?.preference?.availableDate,
              contractorProfile?.preference?.noticePeriod
            ),
            preferFixedTerm: contractorProfile?.preference?.preferFixedTerm || false,
            preferPermanent: contractorProfile?.preference?.preferPermanent || false,
            minSalary: contractorProfile?.preference?.minSalary || 0,
            maxSalary: contractorProfile?.preference?.maxSalary || 0,
            radius: contractorProfile?.preference?.radius || 0,
          });
        }
      }
    }, [dispatch, isOpen, itemEdit, contractorProfile]);

    const onSubmit = async (data: ITalent) => {
      const profileData = {
        firstName: data?.firstName,
        lastName: data?.lastName,
        city: data?.city,
        region: data?.region,
        country: data?.country,
        mobile: data?.phoneNumber,
        title: data?.title,
        email: data?.email,
        visaType: data?.authorityToWork?.value,
      };

      let availableDate = null;
      let noticePeriod = null;
      if (data?.setTime) {
        availableDate = new Date(data?.setTime);
      } else {
        noticePeriod = 2;
      }
      const preferenceData = {
        availableDate: availableDate,
        noticePeriod: noticePeriod,
        minRate: data?.minHourlyRate,
        maxRate: data?.hourlyRate,
        workPolicy: data?.policy?.value,
        preferFixedTerm: data?.preferFixedTerm,
        preferPermanent: data?.preferPermanent,
        minSalary: data?.minSalary,
        maxSalary: data?.maxSalary,
        radius: data?.radius,
        ...(user?.role !== 7 && { org: setting?.idOrganisation })
      };

      // console.log('Profile Data:', profileData);
      // console.log('Preference Data:', preferenceData);

      if (!itemEdit) {
        await handleAddTalent(profileData, preferenceData, data?.cvUpload);
      } else {
        await handleUpdateTalent(itemEdit?.id, profileData, preferenceData, data?.cvUpload);
      }
      dispatch(requestGetTalentList());
      onClose();
    };

    const handleFileInputChange = ({ target }) => {
      if (target?.files[0]) {
        setValue("cvUpload", target.files[0], { shouldValidate: true });
      } else {
        setValue("cvUpload", null);
      }
    };

    const renderAvailableInput = () => {
      switch (availableType?.id) {
        case SET_TIME:
          return (
            <CustomDatePicker
              className={classes.subField}
              name="setTime"
              control={control}
              placeholder="Pick a date..."
              dateFormat="dd/MM/yyyy"
              errorMessage={errors.setTime?.message}
              minDate={moment().toDate()}
              maxDate={moment().add(1, "y").toDate()}
            />
          );
        default:
          return <></>;
      }
    };

    const handleDeactivate = () => {
      dispatch(setLoading(true));
      TalentProfileService.deactivateTalentProfile(itemEdit?.id)
        .then((res) => {
          dispatch(setSuccessMess("Successfully Made Talent De-Activated!"));
          dispatch(setTalentReducer([
            ...talentsList.filter((item) => item?.id !== res?.id),
          ]))
        })
        .catch((err) => {
          dispatch(setErrorMess(err));
        })
        .finally(() => {
          warningOnClose();
          onClose();
          dispatch(setLoading(false));
        });
    };

    const warningOnClose = () => {
      setWarningModalOpen({
        isOpen: false,
        item: null,
      });
    };

    const handleOpenDeleteModal = (id) => {
      setWarningModalOpen({
        isOpen: true,
        item: id,
      });
    };

    const handleShowDetails = () => {
      setShowDetails(true);
    };

    const handleCloseDetails = () => {
      setShowDetails(false);
    };

    const handleSaveDetails = async () => {
      dispatch(setLoading(true));
    
      // Helper function to safely get the value of an input element
      const getInputValue = (selector) => {
        const inputElement = document.querySelector(selector) as HTMLInputElement;
        return inputElement ? inputElement.value : "";
      };
    
      const updatedProfileData = {
        about: getInputValue('textarea[name="about"]'),
      };
    
      const updatedPreferenceData = {
        availableDate: new Date(getInputValue('input[name="availableDate"]')),
        preferFixedTerm: getInputValue('select[name="preferFixedTerm"]') === "Yes",
        preferPermanent: getInputValue('select[name="preferPermanent"]') === "Yes",
        minSalary: Number(getInputValue('input[name="minSalary"]')),
        maxSalary: Number(getInputValue('input[name="maxSalary"]')),
      };
    
      const updatedExperienceData = contractorProfile?.experience?.map((exp, index) => ({
        id: exp.id,  // Include the experience ID
        title: getInputValue(`input[name="experience[${index}].title"]`),
        company: getInputValue(`input[name="experience[${index}].company"]`),
        startDate: new Date(getInputValue(`input[name="experience[${index}].startDate"]`)),
        endDate: new Date(getInputValue(`input[name="experience[${index}].endDate"]`)),
        summary: getInputValue(`textarea[name="experience[${index}].summary"]`),
        responsibilities: getInputValue(`textarea[name="experience[${index}].responsibilities"]`),
      }));
    
      const updatedEducationData = contractorProfile?.education?.map((edu, index) => ({
        id: edu.id,  // Include the education ID
        schoolName: getInputValue(`input[name="education[${index}].schoolName"]`),
        degreeName: getInputValue(`input[name="education[${index}].degreeName"]`),
        endDate: new Date(getInputValue(`input[name="education[${index}].endDate"]`)),
      }));
    
      const updateData = {
        profileData: updatedProfileData,
        preferenceData: updatedPreferenceData,
        experienceData: updatedExperienceData,
        educationData: updatedEducationData,
      };
    
      try {
        await BasicProfileService.putAssistUpdate(contractorProfile.id, updateData);
        dispatch(setSuccessMess("Details updated successfully!"));
      } catch (error) {
        dispatch(setErrorMess(error));
      } finally {
        dispatch(setLoading(false));
        setShowDetails(false);
      }
    };
    
    

    const formatDate = (dateString) => {
      if (!dateString) return '';
      return dateString.split('T')[0];
    };

    const [minSalary, setMinSalary] = useState(contractorProfile?.preference?.minSalary);
    const [maxSalary, setMaxSalary] = useState(contractorProfile?.preference?.maxSalary);

    return (
      <Card className={classes.card}>
        <Modal
          isOpen={isOpen}
          toggle={onClose}
          centered
          scrollable
          className={classes.modal}
        >
          <CardBody className={classes.cardBody}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <p className={classes.title}>
                {!itemEdit ? "Add New Talent" : "Edit Talent"}
              </p>
              <div className={classes.content}>
                <div className={classes.uploadCvWrapper}>
                  <p className={classes.uploadTitle}>
                    {!itemEdit ? "Upload CV:" : "Update CV: (This will rewrite their profile)"}
                  </p>
                  <input
                    className={classes.uploadFile}
                    type="file"
                    placeholder="Upload..."
                    accept=".doc, .docx, .pdf"
                    onChange={handleFileInputChange}
                  />
                </div>

                <CustomInput
                  placeholder="Title"
                  type="text"
                  autoComplete="off"
                  inputRef={register("title")}
                  errorMessage={errors.title?.message}
                />
                <CustomInput
                  placeholder="First name"
                  type="text"
                  autoComplete="off"
                  inputRef={register("firstName")}
                  errorMessage={errors.firstName?.message}
                />
                <CustomInput
                  placeholder="Last name"
                  type="text"
                  autoComplete="off"
                  inputRef={register("lastName")}
                  errorMessage={errors.lastName?.message}
                />
                {itemEdit ? (
                  <div id="email-input-wrapper">
                    <CustomInput
                      placeholder="Email"
                      name="email"
                      type="email"
                      disabled={!!itemEdit}
                      autoComplete="off"
                      inputRef={register("email")}
                      errorMessage={errors.email?.message}
                    />
                    <UncontrolledTooltip target="email-input-wrapper">
                      Email changes must be requested at info@necta.nz
                    </UncontrolledTooltip>
                  </div>
                ) : (
                  <CustomInput
                    placeholder="Email"
                    name="email"
                    type="email"
                    autoComplete="off"
                    inputRef={register("email")}
                    errorMessage={errors.email?.message}
                  />
                )}
                <CustomInput
                  placeholder="Phone number"
                  type="text"
                  autoComplete="off"
                  inputRef={register("phoneNumber")}
                />
                <CustomSelect
                  placeholder="Authority to work"
                  name="authorityToWork"
                  control={control}
                  options={VisaTypes}
                  errorMessage={errors.authorityToWork?.message}
                />
                <div>
                  <span className={clsx("ml-1 mb-3", classes.rateTitle)}>
                    Hourly rate no lower than $...:
                  </span>
                  <CustomSlider
                    name="minHourlyRate"
                    min={30}
                    max={300}
                    control={control}
                    className={classes.sliderRate}
                    errorMessage={errors.minHourlyRate?.message}
                  />
                </div>
                <div className="mb-2">
                  <span className={clsx("ml-1 mb-3", classes.rateTitle)}>
                    Preferred Hourly Rate $...:
                  </span>
                  <CustomSlider
                    name="hourlyRate"
                    min={30}
                    max={300}
                    control={control}
                    className={classes.sliderRate}
                    errorMessage={errors.hourlyRate?.message}
                  />
                </div>
                <CustomSelect
                  placeholder="Policy"
                  name="policy"
                  control={control}
                  options={PreferencePolicyTypes}
                  errorMessage={errors.policy?.message}
                />
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <SearchLocationInput
                      {...field}
                      ref={null}
                      placeholder="Your nearest town or city"
                      errorMessage={errors.address?.message}
                      value={field.value}
                      onChange={(value: any) => {
                        setValue("region", value?.address?.region);
                        setValue("city", value?.address?.city);
                        setValue("country", value?.address?.country);
                        return field.onChange(value?.address?.formattedAddress);
                      }}
                    />
                  )}
                />
                <CustomSelect
                  placeholder="I am available on..."
                  name="availableType"
                  control={control}
                  options={PreferenceAvailableTypes}
                  errorMessage={errors.availableType?.message}
                />
                {renderAvailableInput()}

                {itemEdit && (
                  <div className={classes.btnDeleteWrapper}>
                    <Button
                      size="md"
                      color="danger"
                      type="button"
                      className={classes.btnDelete}
                      onClick={() => handleOpenDeleteModal(itemEdit?.id)}
                    >
                      Deactivate Talent
                    </Button>
                    <Button
                      size="md"
                      color="success"
                      type="button"
                      className={classes.btnShowDetails}
                      onClick={handleShowDetails}
                    >
                      Edit Experience
                    </Button>
                  </div>
                )}
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  size="md"
                  color="default"
                  type="button"
                  className={classes.btn}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button size="md" color="primary" type="submit" className={classes.btn}>
                  Ok
                </Button>
              </div>
            </Form>
          </CardBody>
        </Modal>
        <DeleteWarningModal
          isOpen={warningModalOpen.isOpen}
          onClose={warningOnClose}
          handleDelete={handleDeactivate}
        />

        {showDetails && (
          <Modal isOpen={showDetails} toggle={handleCloseDetails} centered scrollable className={classes.modal}>
            <ModalHeader toggle={handleCloseDetails}>Talent Experience and Education</ModalHeader>
            <CardBody className={classes.cardBody}>
              <Form>
                <h4>Profile</h4>
                <Table bordered responsive striped>
                  <tbody>
                    <tr>
                      <td className={classes.profileTd}>About</td>
                      <td className={classes.textarea}><Input type="textarea" name="about" defaultValue={contractorProfile?.profile?.about || ""} /></td>
                    </tr>
                  </tbody>
                </Table>

                <h4>Preference</h4>
                <Table hover responsive striped>
                  <tbody>
                    <tr>
                      <td className={classes.preferenceTd}>Available Date</td>
                      <td><Input type="date" name="availableDate" defaultValue={formatDate(contractorProfile?.preference?.availableDate)} /></td>
                    </tr>
                    <tr>
                      <td className={classes.preferenceTd}>Accept Permanent</td>
                      <td>
                        <Input type="select" name="preferPermanent" defaultValue={contractorProfile?.preference?.preferPermanent ? 'Yes' : 'No'}>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Input>
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.preferenceTd}>Accept Fixed Term</td>
                      <td>
                        <Input type="select" name="preferFixedTerm" defaultValue={contractorProfile?.preference?.preferFixedTerm ? 'Yes' : 'No'}>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Input>
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.preferenceTd}>Min Salary</td>
                      <td><Input type="text" name="minSalary" defaultValue={contractorProfile?.preference?.minSalary || 0} /></td>
                    </tr>
                    <tr>
                      <td className={classes.preferenceTd}>Preferred Salary</td>
                      <td><Input type="text" name="maxSalary" defaultValue={contractorProfile?.preference?.maxSalary || 0} /></td>
                    </tr>
                  </tbody>
                </Table>

                <h4>Experiences</h4>
                <Table responsive striped>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Summary</th>
                      <th>Responsibilities</th>
                    </tr>
                  </thead>
                  <tbody>
                  {contractorProfile?.experience?.map((exp, index) => (
                      <tr key={index}>
                        <td className={classes.preferenceTd}>
                          Title<Input type="text" name={`experience[${index}].title`} defaultValue={exp.title || ""} />
                          Company <Input type="text" name={`experience[${index}].company`} defaultValue={exp.company || ""} />
                          Date From<Input type="date" name={`experience[${index}].startDate`} defaultValue={formatDate(exp.startDate)} />
                          Date To<Input type="date" name={`experience[${index}].endDate`} defaultValue={formatDate(exp.endDate)} />
                        </td>
                        <td className={classes.textarea}><Input type="textarea" name={`experience[${index}].summary`} defaultValue={exp.summary || ""} /></td>
                        <td className={classes.textarea}><Input type="textarea" name={`experience[${index}].responsibilities`} defaultValue={exp.responsibilities || ""} /></td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <h4>Educations</h4>
                <Table responsive striped>
                  <thead>
                    <tr>
                      <th>Provider</th>
                      <th>Qualification</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                  {contractorProfile?.education?.map((edu, index) => (
                    <tr key={index}>
                      <td><Input type="text" name={`education[${index}].schoolName`} defaultValue={edu.schoolName || ""} /></td>
                      <td><Input type="text" name={`education[${index}].degreeName`} defaultValue={edu.degreeName || ""} /></td>
                      <td><Input type="date" name={`education[${index}].endDate`} defaultValue={formatDate(edu.endDate)} /></td>
                    </tr>
                  ))}
                  </tbody>
                </Table>

                <ModalFooter>
                  <Button color="primary" onClick={handleSaveDetails}>Save Changes</Button>
                  <Button color="secondary" onClick={handleCloseDetails}>Close</Button>
                </ModalFooter>
              </Form>
            </CardBody>
          </Modal>


        )}
      </Card>
    );
  }
);

export default AddNewJob;

import { put, takeLatest, all, call } from "redux-saga/effects";
import { ISetting } from "redux/reducers/Employer";
import {
  GET_SETTING_REQUEST,
  setSettingReducer,
} from "redux/reducers/Employer/actionTypes";
import { setLoading } from "redux/reducers/Status/actionTypes";
import { BasicProfileService } from "services/Employer/NewMember/BasicProfile";
import { OrganizationService } from "services/Employer/NewMember/Organization";

function* requestGetSetting() {
  try {
    yield put(setLoading(true));
    const [basicProfile, organisation] = yield all([
      call(BasicProfileService.getBasicProfile),
      call(OrganizationService.getOrganization),
    ]);
    const setting: ISetting = {
      idBasicProfile: basicProfile?.id,
      firstName: basicProfile?.firstName,
      lastName: basicProfile?.lastName,
      title: basicProfile?.title,
      workPhone: basicProfile?.workPhone,
      recruitOnBehalf: basicProfile?.isRecruiter,
      workPhoneCountryCode: basicProfile?.countryCode,
      photoUrl: basicProfile?.photoUrl,
      headHunter: basicProfile?.isHeadHunter,
      idOrganisation: organisation?.id,
      name: organisation?.name,
      mission: organisation?.mission,
      culture: organisation?.culture,
      industry: organisation?.industry,
      numberOfWorkers: organisation?.numberOfWorkers,
      behaviouralSklls: organisation?.behaviouralSklls,
      nzbn: organisation?.nzbn,
      email: organisation?.email,
      phone: organisation?.phone,
      countryCode: organisation?.countryCode,
      uniqueCode: organisation?.uniqueCode,
      city: organisation?.city,
      country: organisation?.country,
      admin: organisation?.admin || false,
      colour: organisation?.colour || "orange",
      paid: organisation?.paid, 
      numberOfJobs: organisation?.numberOfJobs,
      dateOfRenewal: organisation?.dateOfRenewal,
      paymentTerms: organisation?.paymentTerms,
      invoiceEmail: organisation?.invoiceEmail,
      paymentReference: organisation?.paymentReference,
      terms: organisation?.terms, 
    };
    yield put(setSettingReducer(setting));
  } catch (e: any) {
    console.log(e);
  } finally {
    yield put(setLoading(false));
  }
}

function* getSetting() {
  yield takeLatest(GET_SETTING_REQUEST, requestGetSetting);
}

export default getSetting;

import { API } from "aws-amplify";
import { PreferenceParams } from "models/Contractor/NewMember/Preference";

export class PreferenceService {
  static async postPreference(data: PreferenceParams): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/contractorpreference";
    const myInit = {
      response: true,
      body: data,
    };
    return API.post(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async getPreference(): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = "/contractorpreference";
    const myInit = {
      response: true,
    };
    return API.get(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }

  static async putPreference(data: PreferenceParams, id: number): Promise<any> {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/contractorpreference/${id}`;
    const myInit = {
      response: true,
      body: data,
    };
    return API.put(apiName, path, myInit)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((e) => {
        return Promise.reject(e?.response?.data);
      });
  }
}

import { EMPLOYMENT_TYPE } from "config/constants";
import { push } from "connected-react-router";
import moment from "moment";
import {
  setErrorMess,
  setSuccessMess,
  setLoading,
} from "redux/reducers/Status/actionTypes";
import routes from "routers/routes";
import { BasicProfileService } from "services/Contractor/NewMember/BasicProfile";
import { ContactProfileService } from "services/Contractor/NewMember/ContactProfile";
import { CVParseService } from "services/Contractor/NewMember/CVParse";
import { EducationService } from "services/Contractor/NewMember/Education";
import { ExperienceService } from "services/Contractor/NewMember/Experience";
import { SkillService } from "services/Contractor/NewMember/Skill";
import { setUserReducer } from "redux/reducers/User/actionTypes";
import { setProfileReducer } from "redux/reducers/Contractor/actionTypes";

export class UtilCvParsing {
  static async cvParsing(dispatch, user, profile, data, editProfileByUploadCV) {
    if (!data?.cvUploadFileBase64) {
      console.error("No file uploaded.");
      return false;
    }

    try {
      dispatch(setLoading(true));

      const res = await CVParseService.postCV({
        DocumentAsBase64String: data.cvUploadFileBase64,
        DocumentName: data.cvFileName,
        SkillsSettings: {
          Normalize: true,
          TaxonomyVersion: "",
        },
        IndexingOptions: { IndexId: user.id, DocumentId: user.id },
        DocumentLastModified: moment(new Date()).format("YYYY-MM-DD"),
      });

      // console.log("CV Parse Response:", res);

      const apiArray = [];
      const firstName = res?.name?.split(" ")[0] || "first";
      const lastName = res?.name?.split(" ").slice(1).join(" ") || "last";
      const title = res?.title || "title";
      const about = res?.about || res?.summary || "";

      if (firstName && lastName) {
        const basicProfile = {
          firstName,
          lastName,
          title,
          about,
        };

        const basicProfileService = profile?.basicProfile?.id
          ? BasicProfileService.putBasicProfile(basicProfile, profile.basicProfile.id)
          : BasicProfileService.postBasicProfile(basicProfile);

        apiArray.push(
          basicProfileService
            .then((res) => {
              dispatch(setProfileReducer({ ...profile, basicProfile: res }));
            })
            .catch((e) => dispatch(setErrorMess(e)))
        );
      }

      const countryDialingCodes = {
        "New Zealand": "+64",
        Australia: "+61",
        "United States": "+1",
        "United Kingdom": "+44",
      };

      const adjustPhoneNumber = (phone, country) => {
        const dialingCode = countryDialingCodes[country];
        return dialingCode && phone.startsWith(dialingCode)
          ? "0" + phone.slice(dialingCode.length)
          : phone;
      };

      const contactProfile = {
        countryCode: countryDialingCodes[res?.address?.country] || "+00",
        mobile: adjustPhoneNumber(res?.phone || "0000000", res?.address?.country),
        city: res?.address?.city || "",
        country: res?.address?.country || "",
      };

      if (profile?.contact?.id) {
        apiArray.push(
          ContactProfileService.putContactProfile(contactProfile, profile.contact.id)
            .then((res) => {
              dispatch(setProfileReducer({ ...profile, contact: res }));
            })
            .catch((e) => dispatch(setErrorMess(e)))
        );
      } else {
        apiArray.push(
          ContactProfileService.postContactProfile(contactProfile)
            .then((res) => {
              dispatch(setProfileReducer({ ...profile, contact: res }));
            })
            .catch((e) => dispatch(setErrorMess(e)))
        );
      }

      const educationList = res?.education
        ?.map((item) => ({
          schoolName: item?.provider || "",
          degreeName: item?.qualification || "",
          endDate: item?.date_finished ? new Date(item.date_finished) : null,
          endDateIsPresent: item?.date_finished === "Present" || false,
          country: item?.country || "",
        }))
        .filter((item) => item.schoolName && item.degreeName);

      if (educationList?.length > 0) {
        if (profile?.education.length > 0) {
          const listIds = profile?.education.map((item) => item.id);
          await EducationService.deleteEducation({ ids: listIds }).then(async () => {
            apiArray.push(
              EducationService.putEducation(educationList)
                .then((res) => {
                  dispatch(setProfileReducer({ ...profile, education: res }));
                })
                .catch((e) => dispatch(setErrorMess(e)))
            );
          });
        } else {
          apiArray.push(
            EducationService.postEducation(educationList)
              .then((res) => {
                dispatch(setProfileReducer({ ...profile, education: res }));
              })
              .catch((e) => dispatch(setErrorMess(e)))
          );
        }
      }

      const experienceList = res?.work_experience
        ?.map((item) => ({
          title: item?.title || "",
          company: item?.company || "",
          startDate: item?.dateFrom ? moment(item.dateFrom, "YYYYMM").toISOString() : "",
          endDate: item?.dateTo === "Present" ? null : moment(item.dateTo, "YYYYMM").toISOString(),
          endDateIsPresent: item?.dateTo === "Present",
          summary: item?.summary || "",
          responsibilities: item?.responsibilities || "",
          city: item?.city || "",
          country: item?.country || "",
          employmentType: EMPLOYMENT_TYPE.CONTRACT,
        }))
        .filter((item) => item.title && item.company && item.startDate);

      if (experienceList?.length > 0) {
        if (profile?.experience?.length > 0) {
          const listIds = profile?.experience.map((item) => item.id);
          await ExperienceService.deleteExperience({ ids: listIds }).then(async () => {
            apiArray.push(
              ExperienceService.postExperience(experienceList)
                .then((res) => {
                  dispatch(setProfileReducer({ ...profile, experience: res }));
                })
                .catch((e) => dispatch(setErrorMess(e)))
            );
          });
        } else {
          apiArray.push(
            ExperienceService.postExperience(experienceList)
              .then((res) => {
                dispatch(setProfileReducer({ ...profile, experience: res }));
              })
              .catch((e) => dispatch(setErrorMess(e)))
          );
        }
      }

      const skillList = res?.skills?.slice(0, 10);
      if (skillList?.length >= 3) {
        if (profile?.skill?.id) {
          apiArray.push(
            SkillService.putSkill(skillList, profile.skill.id)
              .then((res) => {
                dispatch(setProfileReducer({ ...profile, skill: res }));
              })
              .catch((e) => dispatch(setErrorMess(e)))
          );
        } else {
          apiArray.push(
            SkillService.postSkill(skillList)
              .then((res) => {
                dispatch(setProfileReducer({ ...profile, skill: res }));
              })
              .catch((e) => dispatch(setErrorMess(e)))
          );
        }
      }

      await Promise.all(apiArray);

      if (editProfileByUploadCV === false) {
        if (
          firstName &&
          firstName.length > 1 &&
          firstName !== "first" &&
          lastName &&
          lastName.length > 1 &&
          title &&
          title.length > 1
        ) {
          return true; // Success
        } else {
          return false; // Failure
        }
      } else {
        if (
          firstName &&
          firstName.length > 1 &&
          firstName !== "first" &&
          lastName &&
          lastName.length > 1 &&
          title &&
          title.length > 1
        ) {
          // console.log("true")
          return true; // Success
        } else {
          // console.log("fasle")
          return false; // Failure
        }
      }
    } catch (e) {
      console.error("Error during CV parsing:", e);
      dispatch(setErrorMess("An error occurred while parsing your CV."));
      return false;
    } finally {
      dispatch(setLoading(false));
    }
  }
}

import { memo, useEffect, useMemo } from "react";
import { Form, Button, Row, CardHeader, Card, FormGroup } from "reactstrap";
import classes from "./styles.module.scss";
import routes from "routers/routes";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { PreferenceService } from "services/Contractor/NewMember/Preference";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import CustomRadioButtons from "components/Common/CustomRadioButtons";
import { ReducerType } from "redux/reducers";
import { setProfileReducer } from "redux/reducers/Contractor/actionTypes";
import CustomSlider from "components/Common/CustomSlider";
import clsx from "clsx";
 
interface Props {}

interface PreferenceFormData {
  id?: number;
  availableDate?: string;
  minHourlyRate: number;
  hourlyRate: number;
  radius?: number;
  availableType: {
    id: number;
    name: string;
  };
  setTime?: Date;
  noticePeriod?: number;
  policy?: {
    id: number;
    name: string;
    value: string;
  };
  travellingRadius?: {
    id: number;
    name: string;
    value: number;
  };
  preferFixedTerm?: boolean;
  preferPermanent?: boolean;
  minSalary?: number;
  maxSalary?: number;
}

const FutureOpportunities = memo((props: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { profile } = useSelector((state: ReducerType) => state.contractor);

  const schema = useMemo(() => {
    return yup.object().shape({
      preferFixedTerm: yup.boolean().nullable(),
      preferPermanent: yup.boolean().nullable(),
      minSalary: yup.number().nullable(),
      maxSalary: yup.number().nullable(),
    });
  }, []);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    reset,
  } = useForm<PreferenceFormData>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const watchPreferPermanent = watch("preferPermanent");
  const watchPreferFixedTerm = watch("preferFixedTerm");

  useEffect(() => {
    if (!watchPreferFixedTerm && !watchPreferPermanent) {
      setValue("minSalary", null);
      setValue("maxSalary", null);
    }
  }, [watchPreferPermanent, watchPreferFixedTerm, setValue]);

  useEffect(() => {
    if (profile?.preference) {
      reset({
        id: profile.preference.id,
        preferFixedTerm: !!profile.preference.preferFixedTerm,
        preferPermanent: !!profile.preference.preferPermanent,
        minSalary: profile.preference.minSalary,
        maxSalary: profile.preference.maxSalary,
      });
    }
  }, [profile, reset]);

  const handleBack = () => {
    dispatch(push(routes.contractor.newMember.preference));
  };

  const onSubmit = (data: PreferenceFormData) => {
    dispatch(setLoading(true));
    if (user?.preferenceCompleted) {
      const preferenceParams = {
        preferFixedTerm: data?.preferFixedTerm,
        preferPermanent: data?.preferPermanent,
        minSalary: data?.minSalary || 0,
        maxSalary: data?.maxSalary || 0,
      };
      if (!data?.preferFixedTerm && !data?.preferPermanent) {
        preferenceParams.minSalary = 0;
        preferenceParams.maxSalary = 0;
      } else {
        if (preferenceParams.minSalary === 0) {
          preferenceParams.minSalary = 40000;
        }
        if (preferenceParams.maxSalary === 0) {
          preferenceParams.maxSalary = 40000;
        }
      }
      PreferenceService.putPreference(preferenceParams, data?.id)
        .then((res) => {
          dispatch(setProfileReducer({ ...profile, preference: res }));
          dispatch(push(routes.contractor.newMember.visa)); // Ensure this runs correctly
        })
        .catch((e) => dispatch(setErrorMess(e)))
        .finally(() => dispatch(setLoading(false)));
    } else {
      dispatch(setErrorMess("Please set up your preference first."));
    }
  };
  
  

  return (
    <Card className={classes.card}>
      <div className={classes.cardContainer}>
        <CardHeader>
          <p className={classes.title}>Future Opportunities</p>
        </CardHeader>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.content}>
            <FormGroup className={classes.section}>
              <CustomRadioButtons
                title={"Would you consider permanent work?"}
                content={[
                  { title: "Yes", value: true},
                  { title: "No", value: false },
                ]}
                name="preferPermanent"
                inputName="preferPermanent"
                control={control}
                className={classes.customRadio}
                errorMessage={errors.preferPermanent?.message}
              />
            </FormGroup>
            <FormGroup className={classes.section}>
              <CustomRadioButtons
                title={"Would you consider fixed-term work?"}
                content={[
                  { title: "Yes", value: true },
                  { title: "No", value: false},
                ]}
                name="preferFixedTerm"
                inputName="preferFixedTerm"
                control={control}
                className={classes.customRadio}
                errorMessage={errors.preferFixedTerm?.message}
              />
            </FormGroup>
            {(watchPreferPermanent || watchPreferFixedTerm) && (
              <>
                <FormGroup className={classes.section}>
                  <span className={clsx("ml-1 mb-3", classes.rateTitle)}>
                    Salary no lower than $...
                  </span>
                  <CustomSlider
                    name="minSalary"
                    min={40000}
                    max={500000}
                    step={1000}
                    control={control}
                    className={classes.sliderRate}
                    errorMessage={errors.minSalary?.message}
                  />
                </FormGroup>
                <FormGroup className={classes.section}>
                  <span className={clsx("ml-1 mb-3", classes.rateTitle)}>
                    Preferred salary $...
                  </span>
                  <CustomSlider
                    name="maxSalary"
                    min={40000}
                    max={500000}
                    step={1000}
                    control={control}
                    className={classes.sliderRate}
                    errorMessage={errors.maxSalary?.message}
                  />
                </FormGroup>
              </>
            )}
          </div>
          <Row className="justify-content-between p-0 m-0">
            <Button
              size="md"
              color="default"
              type="button"
              className={classes.btn}
              onClick={handleBack}
            >
              Back
            </Button>
            <Button
              size="md"
              color="primary"
              type="submit"
              className={classes.btn}
            >
              Next
            </Button>
          </Row>
        </Form>
      </div>
    </Card>
  );
});

export default FutureOpportunities;

import { memo, useEffect, useState, useMemo } from 'react';
import {
  Button,
  Container,
  Table,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Collapse,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import FooterTransparentEmployer from 'components/Footers/FooterTransparentEmployer';
import classes from './styles.module.scss';
import clsx from 'clsx';
import CustomInput from 'components/Common/CustomInput';
import AddEditTalent from './AddEditTalent';
import { setErrorMess, setLoading } from 'redux/reducers/Status/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { TalentProfileService } from 'services/Employer/Listing/Talent/TalentProfile';
import { TalentPreferenceService } from 'services/Employer/Listing/Talent/TalentPreference';
import {
  S3_UPLOAD_TYPE_PRIFIX,
  USER_STATUS,
  WORK_POLICY,
} from 'config/constants';
import { UtilContractor } from 'utils/contractor';
import { convertVisaType } from 'utils/moj';
import { ReducerType } from 'redux/reducers';
import { setTalentReducer } from 'redux/reducers/Employer/actionTypes';
import moment from 'moment';
import RateFilter from './RateFilter';
import CustomDatePicker from 'components/Common/CustomDatePicker';
import CustomDropdown from 'components/Common/CustomDropdown/CustomDropdown';
import { saveAs } from 'file-saver';
import AssignToOpportunity from './AssignToOpportunity';
import { push } from 'connected-react-router';
import { useModal } from 'components/Modals';
import { JobOfferService } from 'services/Contractor/Offer/Offer';
import { JobService } from 'services/Employer/Listing/Job';
import { useWebSocket } from 'services/Message/User/WebSocketProvider.js';
import routes from 'routers/routes';
import { useHistory } from 'react-router-dom';
import { CVParseService } from 'services/Contractor/NewMember/CVParse';
import { AssistCreate } from 'services/Contractor/NewMember/AssistCreate';

interface Props {}

// eslint-disable-next-line no-empty-pattern
const Talent = memo(({}: Props) => {
  const dispatch = useDispatch();
  const { talentsList } = useSelector((state: ReducerType) => state.employer);
  const [talentsListRender, setTalentsListRender] = useState([]);
  const [isOpenToggleArr, setIsOpenToggleArr] = useState([]);
  const [policyFilter, setPolicyFilter] = useState(null);
  const [minRateFilter, setMinRateFilter] = useState(null);
  const [maxRateFilter, setMaxRateFilter] = useState(null);
  const [availabilityFilter, setAvailabilityFilter] = useState(null);
  const [cityFilter, setCityFilter] = useState(null);
  const [countryFilter, setCountryFilter] = useState(null);
  const [areFiltersVisible, setAreFiltersVisible] = useState(false);
  // status state
  const [statusFilter, setStatusFilter] = useState(null);
  const [isOpenFilterRateModal, setIsOpenFilterRateModal] = useState({
    isOpen: false,
    type: null,
  });

  const allCountries = useMemo(() => {
    if (talentsListRender) {
      // Create a Set to automatically remove duplicates
      const countriesSet = new Set(
        talentsListRender.map((item) => item.country)
      );
      return Array.from(countriesSet);
    } else {
      return [];
    }
  }, [talentsListRender]);

  const citiesInSelectedCountry = useMemo(() => {
    if (talentsListRender && countryFilter) {
      const citiesSet = new Set(
        talentsListRender
          .filter((item) => item.country === countryFilter)
          .map((item) => item.city)
      );
      return Array.from(citiesSet);
    } else {
      return [];
    }
  }, [talentsListRender, countryFilter]);

  const handleProgressOffer = async (jobId, contractorId, employerId) => {
    dispatch(setLoading(true));

    try {
      // Create the offer
      const creationResponse = await JobService.postJobOffer({
        job: jobId,
        contractor: contractorId,
        employer: employerId,
      });

      console.log('Response from postJobOffer:', creationResponse);

      // Assuming the response contains the connectOfferId you need
      const connectOfferId = creationResponse.id;

      console.log(
        'Fetching the offer with contractorId:',
        contractorId,
        'and connectOfferId:',
        connectOfferId
      );

      // Update the status of the offer using a PUT request
      await JobOfferService.updateOfferStatus(connectOfferId, {
        job: jobId,
        contractor: contractorId,
        employer: employerId,
      });

      console.log('Offer status updated');
    } catch (err) {
      console.log('Error in updating or accepting the offer:', err);
      dispatch(setErrorMess(err));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleRedirectToMessage = (webSocket, myId, receiverId) => {
    // Create an instance of the history object

    // Set up room through WebSocket to send private message:

    if (webSocket && webSocket.webSocket) {
      webSocket.webSocket.send(
        JSON.stringify({
          action: 'sendPrivate',
          data: {
            userId: myId,
            receiverId: receiverId,
          },
        })
      );
    }
  };
  // this is extremely not secure. needs changing 
  const routeSeeFullProfile = (candidateID) => {
    dispatch(push(`/jobs/73957395/candidates/${candidateID}`));
  };

  // Assign to opportunity state and toggle
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false); // Assign to opportunity modal state

  const toggleAssignModal = () => {
    setIsAssignModalOpen(!isAssignModalOpen);
  };

  const handleClearFilters = () => {
    setPolicyFilter(null);
    setCityFilter(null);
    setCountryFilter(null);
    setMinRateFilter(null);
    setMaxRateFilter(null);
    setAvailabilityFilter(null);
    // status
    setStatusFilter(null);
  };

  // useEffect(() => {
  //   if (talentsList.length === 0) {
  //     // Dispatch an action to request the talent list from the API
  //     dispatch({ type: 'GET_TALENTS_REQUEST' });
  //   }
  // }, []);

  useEffect(() => {
    setTalentsListRender([...talentsList]);

    const isOpenToggleArr = [];
    talentsList.forEach(() => {
      isOpenToggleArr.push(false);
    });
    setIsOpenToggleArr(isOpenToggleArr);
  }, [dispatch, talentsList]);

  useEffect(() => {
    let filterList = [...talentsList];

    if (policyFilter) {
      const filterPolicyRender = filterList.filter(
        (item) => item?.workPolicy === policyFilter
      );

      filterList = [...filterPolicyRender];
    }

    // status filter
    if (statusFilter) {
      const filterStatusRender = filterList.filter(
        (item) => item?.status === statusFilter
      );
      filterList = [...filterStatusRender];
    }

    if (minRateFilter) {
      const filterMinRateRender = filterList.filter(
        (item) => item?.minRate >= minRateFilter
      );
      filterList = [...filterMinRateRender];
    }

    if (maxRateFilter) {
      const filterMaxRateRender = filterList.filter(
        (item) => item?.maxRate <= maxRateFilter
      );
      filterList = [...filterMaxRateRender];
    }

    if (availabilityFilter) {
      const filterAvailabilityRender = filterList.filter((item) => {
        if (item?.availableDate) {
          return (
            moment(availabilityFilter).diff(
              moment(item?.availableDate),
              'seconds'
            ) >= 0
          );
        } else if (item?.noticePeriod) {
          return (
            moment(availabilityFilter).diff(
              moment().add(2, 'weeks'),
              'seconds'
            ) >= 0
          );
        }
      });
      filterList = [...filterAvailabilityRender];
    }

    if (countryFilter) {
      filterList = filterList.filter((item) => item.country === countryFilter);
    }

    if (cityFilter) {
      filterList = filterList.filter((item) => item.city === cityFilter);
    }

    setTalentsListRender([...filterList]);
  }, [
    policyFilter,
    minRateFilter,
    maxRateFilter,
    statusFilter,
    availabilityFilter,
    cityFilter,
    countryFilter,
  ]);

  const handleToggleSubTable = (index) => {
    const newIsOpen = [...isOpenToggleArr];
    newIsOpen[index] = !newIsOpen[index];
    setIsOpenToggleArr(newIsOpen);
  };

  const [valueOfSearchInput, setValueOfSearchInput] = useState('');

  const [addEditModalOpen, setAddEditModalOpen] = useState({
    isOpenModal: false,
    itemEditModal: false,
    editedItem: null,
  });

  const handleOpenAddTalentForm = () => {
    setAddEditModalOpen({
      itemEditModal: false,
      isOpenModal: true,
      editedItem: null,
    });
  };

  const handleOpenEditTalentForm = (item) => {
    setAddEditModalOpen({
      itemEditModal: true,
      isOpenModal: true,
      editedItem: item,
    });
  };

  const onCloseAddEditModal = () => {
    setAddEditModalOpen({
      isOpenModal: false,
      itemEditModal: false,
      editedItem: null,
    });
  };

  const handleSearch = () => {
    dispatch(setLoading(true));
    TalentProfileService.getFilterNameAllTalents(valueOfSearchInput)
      .then((res) => {
        setTalentsListRender([...res]);
      })
      .catch((err) => dispatch(setErrorMess(err)))
      .finally(() => dispatch(setLoading(false)));
  };

  const handleRotateIcon = (e) => {
    e.target.classList.toggle(classes.down);
  };

  const uploadCv = (profileId, cv, isPost = false) => {
    const cvKey = `${profileId}-${S3_UPLOAD_TYPE_PRIFIX.TALENT_CV}`;

    cv.arrayBuffer()
      .then((arrayBuffer) => {
        const blob = new Blob([new Uint8Array(arrayBuffer)], {
          type: cv?.type,
        });
        TalentProfileService.postCv(blob, cvKey)
          .then(async (key) => {
            if (isPost) {
              TalentProfileService.putTalentProfile(
                { cvUrl: key },
                profileId
              ).catch((err) => dispatch(setErrorMess(err)));
            }
          })
          .catch((e) => dispatch(setErrorMess(e)));
      })
      .catch((e) => dispatch(setErrorMess(e)));
  };

  const handleAddTalent = async (profileData, preferenceData, cvUpload) => {
    dispatch(setLoading(true));

    try {
      let combinedData = {
        ...profileData,
        ...preferenceData,
      };

      // Call the AWS Lambda function to create a user in Cognito
      const response = await AssistCreate.postAssistCreate({
        username: profileData.email, 
        email: profileData.email,
      });

      if (cvUpload) {
        const reader = new FileReader();
        reader.readAsDataURL(cvUpload);
        const documentName = cvUpload.name; 
        // console.log('CV Upload:', cvUpload);
        reader.onloadend = async () => {
          const base64data = (reader.result as string).split(',')[1]; // Remove the data type prefix
          const cvData = await CVParseService.postCV({
            DocumentAsBase64String: base64data,
            DocumentLastModified: new Date().toISOString().split('T')[0], // Current date in YYYY-MM-DD format
            DocumentName: documentName,
          });
          // console.log('Parsed CV Data:', cvData);

          // Extract necessary information from cvData
          const skills = cvData.Value.ResumeData.Skills.Raw.slice(0, 5).map(
            (skill) => skill.Name
          );
          const title =
            cvData.Value.ResumeData.EmploymentHistory.Positions[0].JobTitle.Raw;
          const totalExperience =
            cvData.Value.ResumeData.EmploymentHistory.ExperienceSummary
              .MonthsOfWorkExperience;
          const currentCompany =
            cvData.Value.ResumeData.EmploymentHistory.Positions[0].Employer.Name
              .Raw;
          const summary =
            cvData.Value.ResumeData.EmploymentHistory.ExperienceSummary
              .Description;

          const education =
            cvData.Value.ResumeData.Education.EducationDetails.map((edu) => ({
              provider: edu.SchoolName.Raw,
              qualification: edu.Degree.Raw,
            }));

          const experience =
            cvData.Value.ResumeData.EmploymentHistory.Positions.map((pos) => ({
              title: pos.JobTitle.Raw,
              company: pos.Employer.Name.Raw,
              dateFrom: pos.StartDate.Date,
              dateTo: pos.EndDate.Date,
              summary: pos.Description,
            }));

          // Combine profileData, preferenceData, and extracted CV data
          combinedData = {
            ...combinedData,
            skills,
            title,
            totalExperience,
            currentCompany,
            summary,
            education,
            experience,
          };

          // console.log('Combined Data:', combinedData);
        };
        reader.onerror = () => {
          console.error('FileReader error');
        };
      }

      // If no CV is provided, the request is sent with only profileData and preferenceData
      const profileRes = await TalentProfileService.postTalentAssistProfile(
        combinedData
      );
    } catch (err) {
      dispatch(setErrorMess(err));
    } finally {
      dispatch(setLoading(false));
    }
  };

  //

  // const preferenceRes = await TalentPreferenceService.postTalentPreference({
  //   ...preferenceData,
  //   talent: profileRes?.id,
  // });

  // const talentData = {
  //   ...profileRes,
  //   availableData: preferenceRes?.availableData,
  //   minRate: preferenceRes?.minRate,
  //   maxRate: preferenceRes?.maxRate,
  //   radius: preferenceRes?.radius,
  //   workPolicy: preferenceRes?.workPolicy,
  //   noticePeriod: preferenceRes?.noticePeriod,
  //   minSalary: preferenceRes?.minSalary,
  //   maxSalary: preferenceRes?.maxSalary,
  //   id: profileRes?.id,
  // };

  // dispatch(setTalentReducer([...talentsList, talentData]));

  const handleUpdateTalent = (
    profileId,
    profileData,
    preferenceData,
    cvUpload
  ) => {
    dispatch(setLoading(true));
    const combinedData = { ...profileData, ...preferenceData };
    //console.log("Profile" + JSON.stringify(profileData));
    //console.log("Preference" + JSON.stringify(preferenceData));
    //console.log("combined data" + JSON.stringify(combinedData));
    Promise.all([
      TalentProfileService.putAssistUpdateProfile(combinedData, profileId),
      //TalentPreferenceService.putTalentPreference(preferenceData, profileId),
    ])
      // .then(async (res) => {
      //   const [profile] = res;
      //   if (cvUpload) {
      //     await uploadCv(profile?.id, cvUpload);
      //   }
      //   const index = talentsList?.findIndex(
      //     (item) => item?.id === profile?.id
      //   );
      //   let newList = [...talentsList];

      //   const talentData = {
      //     ...profile,
      //     availableData: profile?.availableData,
      //     minRate: profile?.minRate,
      //     maxRate: profile?.maxRate,
      //     radius: profile?.radius,
      //     workPolicy: profile?.workPolicy,
      //     noticePeriod: profile?.noticePeriod,
      //     minSalary: profile?.minSalary,
      //     maxSalary: profile?.maxSalary,
      //     id: profile?.id,
      //   };
      //   console.log(talentData)

      //   newList.splice(index, 1, talentData);
      //   dispatch(setTalentReducer(newList));
      // })
      .catch((err) => dispatch(setErrorMess(err)))
      .finally(() => dispatch(setLoading(false)));
  };

  const handleClickFilterPolicy = (status) => {
    setPolicyFilter(status);
  };

  // statuses
  const handleClickFilterStatus = (status) => {
    setStatusFilter(status);
  };

  const onCloseRateFilter = () => {
    setIsOpenFilterRateModal({
      isOpen: false,
      type: null,
    });
  };

  const handleDownloadCv = (talent) => {
    dispatch(setLoading(true));
    TalentProfileService.getCv(talent?.cvUrl)
      .then((res) => {
        const fileType = (res as any)?.type;
        let fileName = `${talent?.firstName}-${talent?.lastName}-CV.docx`;
        if (fileType === 'application/pdf') {
          fileName = `${talent?.firstName}-${talent?.lastName}-CV.pdf`;
        }
        const myFile = new File([res as BlobPart], fileName, {
          type: (res as any)?.type,
        });
        saveAs(myFile, fileName);
      })
      .catch((e) => dispatch(setErrorMess(e)))
      .finally(() => dispatch(setLoading(false)));
  };

  const { onOpen: openAssignOpportunity } = useModal('AssignOpportunity');
  const webSocket = useWebSocket();
  const history = useHistory();
  const { user } = useSelector((state: ReducerType) => state.user);
  return (
    <>
      <div
        className={clsx('page-header header-filter', classes.pageHeader)}
        filter-color='yellow'
      >
        <div
          className='page-header-image'
          style={{
            backgroundImage: 'url(' + require('assets/img/project25.jpg') + ')',
          }}
        />
        <div className={clsx('content mb-0', classes.content)}>
          <Container className={classes.container}>
            <p className={classes.title}>TALENT</p>

            <div className={classes.leftAlignDropdowns}>
              <Button
                color='primary'
                size='sm'
                className={classes.filtersButton}
                onClick={() => {
                  setAreFiltersVisible(!areFiltersVisible);
                  console.log(areFiltersVisible);
                }}
              >
                Filters
              </Button>
              <Button
                size='sm'
                className={classes.btnClearFilter}
                onClick={handleClearFilters}
              >
                <i className='fas fa-minus-circle mr-1' />
                Clear filters
              </Button>
              <Button
                color='primary'
                size='sm'
                className={classes.btnContent}
                onClick={handleOpenAddTalentForm}
              >
                <i className='now-ui-icons ui-1_simple-add mr-1' />
                Add New
              </Button>
            </div>
            <div className={classes.tableHeaderContainer}>
              {areFiltersVisible && (
                <div className={classes.filterWrapper}>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSearch();
                    }}
                  >
                    <CustomInput
                      endIcon={
                        <i
                          className={clsx('fa fa-search', classes.searchIcon)}
                          onClick={handleSearch}
                        />
                      }
                      placeholder='Search name or title...'
                      onChange={(e) => {
                        setValueOfSearchInput(e.target.value);
                      }}
                    />
                  </form>

                  <CustomDatePicker
                    name={`filterAvailability`}
                    placeholder='Filter on Availability'
                    dateFormat='dd/MM/yyyy'
                    errorMessage={''}
                    onChange={(date) => setAvailabilityFilter(date)}
                    className={classes.searchNameInput}
                    selected={availabilityFilter}
                  />

                  <CustomDropdown
                    title='Filter on Country'
                    options={allCountries}
                    selectedOption={countryFilter}
                    onChange={(selectedCountry) => {
                      setCountryFilter(selectedCountry);
                      setCityFilter(''); // Reset city filter when country changes
                    }}
                  />
                  <CustomDropdown
                    title='Filter on City'
                    options={citiesInSelectedCountry}
                    selectedOption={cityFilter}
                    onChange={(selectedCity) => setCityFilter(selectedCity)}
                  />
                </div>
              )}
            </div>
            <div className={classes.tableContainer}>
              <Table className={classes.table} responsive>
                <thead>
                  <tr>
                    <th>Candidate Name</th>
                    <th>Title</th>
                    <th className={clsx(classes.dNoneMd, classes.policyFilter)}>
                      <UncontrolledDropdown
                        nav
                        className={classes.uncontrolledDropdown}
                      >
                        <DropdownToggle
                          caret
                          color='default'
                          data-toggle='dropdown'
                          id='navbarDropdownMenuLink'
                          nav
                          onClick={(e) => e.preventDefault()}
                          className={classes.dropdownWrapper}
                        >
                          Policy
                        </DropdownToggle>
                        <DropdownMenu
                          aria-labelledby='navbarDropdownMenuLink'
                          className={classes.dropdownMenu}
                          container='body'
                        >
                          <DropdownItem
                            onClick={() => handleClickFilterPolicy(null)}
                          >
                            ALL POLICIES{' '}
                            {!policyFilter && (
                              <i className='now-ui-icons ui-1_check mr-1' />
                            )}
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              handleClickFilterPolicy(WORK_POLICY.MIXED)
                            }
                          >
                            {
                              UtilContractor.getCurrentWorkPolicy(
                                WORK_POLICY.MIXED
                              ).name
                            }{' '}
                            {policyFilter === WORK_POLICY.MIXED && (
                              <i className='now-ui-icons ui-1_check mr-1' />
                            )}
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              handleClickFilterPolicy(WORK_POLICY.OFFICE)
                            }
                          >
                            {
                              UtilContractor.getCurrentWorkPolicy(
                                WORK_POLICY.OFFICE
                              ).name
                            }{' '}
                            {policyFilter === WORK_POLICY.OFFICE && (
                              <i className='now-ui-icons ui-1_check mr-1' />
                            )}
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              handleClickFilterPolicy(
                                WORK_POLICY.WORK_FROM_HOME
                              )
                            }
                          >
                            {
                              UtilContractor.getCurrentWorkPolicy(
                                WORK_POLICY.WORK_FROM_HOME
                              ).name
                            }{' '}
                            {policyFilter === WORK_POLICY.WORK_FROM_HOME && (
                              <i className='now-ui-icons ui-1_check mr-1' />
                            )}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </th>
                    <th
                      className={clsx(classes.dNoneMd, classes.rateFilter)}
                      onClick={() =>
                        setIsOpenFilterRateModal({ isOpen: true, type: 'min' })
                      }
                    >
                      Rate (Min) <i className='fas fa-filter' />
                    </th>
                    <th
                      className={clsx(classes.rateFilter)}
                      onClick={() =>
                        setIsOpenFilterRateModal({ isOpen: true, type: 'max' })
                      }
                    >
                      Rate (Pref) <i className='fas fa-filter' />
                    </th>
                    <th className={clsx(classes.dNoneMd, classes.policyFilter)}>
                      <UncontrolledDropdown
                        nav
                        className={classes.uncontrolledDropdown}
                      >
                        <DropdownToggle
                          caret
                          color='default'
                          data-toggle='dropdown'
                          id='navbarDropdownMenuLink'
                          nav
                          onClick={(e) => e.preventDefault()}
                          className={classes.dropdownWrapper}
                        >
                          Status
                        </DropdownToggle>
                        <DropdownMenu
                          aria-labelledby='navbarDropdownMenuLink'
                          className={classes.dropdownMenu}
                          container='body'
                        >
                          <DropdownItem
                            onClick={() => handleClickFilterStatus(null)}
                          >
                            ALL STATUSES
                            {!statusFilter && (
                              <i className='now-ui-icons ui-1_check mr-1' />
                            )}
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              handleClickFilterStatus(USER_STATUS.AVAILABLE)
                            }
                          >
                            {
                              UtilContractor.getCurrentUserStatus(
                                USER_STATUS.AVAILABLE
                              ).name
                            }{' '}
                            {statusFilter === USER_STATUS.AVAILABLE && (
                              <i className='now-ui-icons ui-1_check mr-1' />
                            )}
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              handleClickFilterStatus(USER_STATUS.INTERVIEW)
                            }
                          >
                            {
                              UtilContractor.getCurrentUserStatus(
                                USER_STATUS.INTERVIEW
                              ).name
                            }{' '}
                            {statusFilter === USER_STATUS.INTERVIEW && (
                              <i className='now-ui-icons ui-1_check mr-1' />
                            )}
                          </DropdownItem>
                          {/* <DropdownItem
                            onClick={() =>
                              handleClickFilterStatus(USER_STATUS.CONTRACT)
                            }
                          >
                            {
                              UtilContractor.getCurrentUserStatus(
                                USER_STATUS.CONTRACT
                              ).name
                            }{" "}
                            {statusFilter === USER_STATUS.CONTRACT && (
                              <i className="now-ui-icons ui-1_check mr-1" />
                            )}
                          </DropdownItem> */}
                          <DropdownItem
                            onClick={() =>
                              handleClickFilterStatus(USER_STATUS.ENGAGED)
                            }
                          >
                            {
                              UtilContractor.getCurrentUserStatus(
                                USER_STATUS.ENGAGED
                              ).name
                            }{' '}
                            {statusFilter === USER_STATUS.ENGAGED && (
                              <i className='now-ui-icons ui-1_check mr-1' />
                            )}
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              handleClickFilterStatus(USER_STATUS.DEACTIVATED)
                            }
                          >
                            {
                              UtilContractor.getCurrentUserStatus(
                                USER_STATUS.DEACTIVATED
                              ).name
                            }{' '}
                            {statusFilter === USER_STATUS.DEACTIVATED && (
                              <i className='now-ui-icons ui-1_check mr-1' />
                            )}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {talentsListRender?.length
                    ? talentsListRender.map((item, index) => {
                        //console.log('Talent item:', JSON.stringify(item)); // This will log the entire item
                        //=console.log('Talent status:', item?.status);

                        return (
                          <>
                            <tr key={index}>
                              <td
                                scope='row'
                                onClick={() => handleToggleSubTable(index)}
                              >
                                {item?.firstName} {item?.lastName}
                              </td>
                              <td
                                scope='row'
                                onClick={() => handleToggleSubTable(index)}
                              >
                                {item?.title}
                              </td>
                              <td className={classes.dNoneMd}>
                                {
                                  UtilContractor.getCurrentWorkPolicy(
                                    item?.workPolicy
                                  )?.name
                                }
                              </td>
                              <td className={classes.dNoneMd}>
                                ${item?.minRate}
                              </td>
                              <td
                                scope='row'
                                onClick={() => handleToggleSubTable(index)}
                              >
                                ${item?.maxRate}
                              </td>
                              <td className={classes.dNoneMd}>
                                {item?.status}
                              </td>
                              <td className={classes.dNoneMd}>
                                <Button
                                  size='sm'
                                  className={classes.btnContent}
                                  onClick={() => handleOpenEditTalentForm(item)}
                                >
                                  <i className='now-ui-icons ui-2_settings-90 mr-1' />
                                  Edit
                                </Button>
                              </td>
                              <td
                                className={clsx(
                                  'text-center',
                                  classes.iconRotateWrapper
                                )}
                              >
                                <i
                                  className={clsx(
                                    'fa fa-arrow-alt-circle-down',
                                    classes.rotate
                                  )}
                                  onClick={(e) => {
                                    handleRotateIcon(e);
                                    handleToggleSubTable(index);
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                colSpan={7}
                                className={clsx('p-0', classes.subTable)}
                              >
                                <Collapse isOpen={isOpenToggleArr[index]}>
                                  <div className={classes.collapseSubTable}>
                                    <Row
                                      tag='ul'
                                      className={clsx(
                                        classes.collapseInformation
                                      )}
                                    >
                                      <Col tag='li' xs={6} md={4}>
                                        <h2>Contact Number</h2>
                                        <p>{item?.mobile}</p>
                                      </Col>
                                      <Col tag='li' xs={6} md={4}>
                                        <h2>Email</h2>
                                        <p>{item?.email}</p>
                                      </Col>
                                      <Col tag='li' xs={6} md={4}>
                                        <h2 className={classes.dNoneMd}>
                                          Authority to Work
                                        </h2>
                                        <p className={classes.dNoneMd}>
                                          {
                                            convertVisaType(item?.visaType)
                                              ?.name
                                          }
                                        </p>
                                      </Col>
                                      <Col tag='li' xs={6} md={4}>
                                        <h2>Address</h2>
                                        <p>
                                          {item?.region}, {item?.city} -{' '}
                                          {item?.country}
                                        </p>
                                      </Col>
                                      <Col tag='li' xs={6} md={4}>
                                        <h2>Availability</h2>
                                        <p>
                                          {item?.availableDate
                                            ? moment(
                                                item?.availableDate
                                              ).format(`DD/MM/YYYY`)
                                            : `${item?.noticePeriod} weeks`}
                                        </p>
                                      </Col>
                                      <Col tag='li' xs={6} md={4}></Col>
                                    </Row>
                                    <div className={classes.collapseButton}>
                                      <UncontrolledDropdown
                                        nav
                                        className={classes.uncontrolledDropdown}
                                      >
                                        <DropdownToggle
                                          caret
                                          color='default'
                                          data-toggle='dropdown'
                                          id='navbarDropdownMenuLink2'
                                          nav
                                          onClick={(e) => e.preventDefault()}
                                          className={classes.dropdownWrapper}
                                        >
                                          <p
                                            className={classes.dropdownContent}
                                          >
                                            More
                                          </p>
                                        </DropdownToggle>
                                        {/* <UncontrolledDropdown>
                                        <DropdownToggle>
                                          Assign to opportunity
                                          <DropdownMenu
                                            className={classes.dropdownMenu}
                                          >
                                            <DropdownItem>
                                              New DropdownMenu
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </DropdownToggle>
                                      </UncontrolledDropdown> */}
                                        <DropdownMenu
                                          aria-labelledby='navbarDropdownMenuLink2'
                                          className={classes.dropdownMenu}
                                          container='body'
                                        >
                                          {/* <DropdownItem
                                        onClick={() => {
                                          handleRedirectToMessage(webSocket, user.id, item?.id);
                                          history.push(routes.employer.listing.messenger);
                                        }}>
                                          Message
                                        </DropdownItem> */}

                                          <DropdownItem
                                            onClick={() =>
                                              routeSeeFullProfile(
                                                item?.id
                                                // candidate?.id
                                              )
                                            }
                                          >
                                            See details
                                          </DropdownItem>
                                          {item?.cvUrl && (
                                            <DropdownItem
                                              onClick={() =>
                                                handleDownloadCv(item)
                                              }
                                            >
                                              Download ASDFCV
                                            </DropdownItem>
                                          )}
                                          <DropdownItem
                                            onClick={() =>
                                              openAssignOpportunity({
                                                contractorId: item?.id,
                                              })
                                            }
                                          >
                                            Assign to opportunity
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </div>
                                  </div>
                                </Collapse>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : ''}
                </tbody>
              </Table>
            </div>
          </Container>
        </div>
        {/* <FooterTransparentEmployer /> */}
      </div>
      <AddEditTalent
        onClose={onCloseAddEditModal}
        isOpen={addEditModalOpen.isOpenModal}
        itemEdit={addEditModalOpen.editedItem}
        handleAddTalent={handleAddTalent}
        handleUpdateTalent={handleUpdateTalent}
      />
      <RateFilter
        onClose={onCloseRateFilter}
        isOpen={isOpenFilterRateModal.isOpen}
        rateType={isOpenFilterRateModal.type}
        updateFilterMaxRate={(value) => setMaxRateFilter(value)}
        updateFilterMinRate={(value) => setMinRateFilter(value)}
        defaultFilterRate={{
          minRateFilter: minRateFilter || 30,
          maxRateFilter: maxRateFilter || 300,
        }}
      />
    </>
  );
});

export default Talent;

import { memo, useEffect, useMemo, useState } from "react";
import { Form, Button, Row, CardHeader, Card } from "reactstrap";
import ImageUpload from "components/CustomUpload";
import classes from "./styles.module.scss";
import routes from "routers/routes";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "components/Common/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import CustomCheckbox from "components/Common/CustomCheckbox";
import { ReducerType } from "redux/reducers";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { ImageService } from "services/Image";
import { BasicProfileService } from "services/Employer/NewMember/BasicProfile";
import { S3_UPLOAD_TYPE_PRIFIX } from "config/constants";
import password from "secure-random-password";
// import { UserChatEngineService } from "services/Message/User/UserChatEngine";
import { UserService } from "services/User";
import { setUserReducer } from "redux/reducers/User/actionTypes";
import { setSettingReducer } from "redux/reducers/Employer/actionTypes";
import { UtilDefaultAvatar } from "utils/defaultAvatar";
import { getSetting } from "redux/reducers/Employer/actionTypes";
import CustomCheckboxFlip from 'components/Common/CustomCheckBoxFlip';

interface Props {}

interface BasicProfileFormData {
  id?: number;
  firstName: string;
  lastName: string;
  title: string;
  workPhone?: string;
  countryCode?: string;
  avatar?: File;
  // recruitOnBehalf?: boolean;
}

// eslint-disable-next-line no-empty-pattern
const BasicProfile = memo(({}: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReducerType) => state.user);
  const { setting } = useSelector((state: ReducerType) => state.employer);

  const schema = useMemo(() => {
    return yup.object().shape({
      id: yup.number(),
      avatar: yup.mixed(),
      firstName: yup
        .string()
        .required("This field is required")
        .min(2, "At least 2 characters")
        .max(64, "At least 64 characters"),
      lastName: yup
        .string()
        .required("This field is required")
        .min(2, "At least 2 characters")
        .max(64, "At least 64 characters"),
      title: yup.string().required("This field is required"),
      workPhone: yup.string(),
    });
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    control,
  } = useForm<BasicProfileFormData>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const addSubscriberToMailchimp = (email, firstName, lastName) => {
    const headers = {
      "Authorization": `Bearer ${process.env.MAILCHIMP_API_KEY}`,
      "Content-Type": "application/json"
    };
    
    const data = {
      "email_address": email,
      "status": "subscribed",
      "tags": ["employer"],
      "merge_fields": {
        "FNAME": firstName,
        "LNAME": lastName
      }
    };
  
    fetch(process.env.MAILCHIMP_URL, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data)
    })
    .then(response => response.text())
    .then(text => console.log(`Mailchimp Response: ${text}`))
    .catch(error => console.error(`Failed to add subscriber to Mailchimp: ${error}`));
  };

  const handleBack = () => {
    dispatch(push(routes.employer.listing.dashboard));
  };
  const [isAssist, setIsAssist] = useState(true);


  // const handleCheckboxChange = (isChecked: boolean) => {
  //   setIsAssist(isChecked);
  //   setValue("recruitOnBehalf", isChecked);  
  //   };

  useEffect(() => {
    if (setting) {
      reset({
        id: setting.idBasicProfile,
        firstName: setting.firstName,
        lastName: setting.lastName,
        title: setting.title,
        workPhone: setting.workPhone,
        countryCode: setting.countryCode,
        // recruitOnBehalf: setting.recruitOnBehalf,
      });
      if (setting.photoUrl) {
        ImageService.getImageBlob(setting.photoUrl)
          .then((res) => {
            setValue("avatar", blobToFile(res as Blob, "avatar.png"));
          })
          .catch((e) => dispatch(setErrorMess(e)));
      }
    }
  }, [dispatch, reset, setValue, setting]);

  const blobToFile = (theBlob: Blob, fileName: string): File => {
    return new File([theBlob as any], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type,
    });
  };

  const onSubmit = async (data: BasicProfileFormData) => {
    dispatch(setLoading(true));
    await ImageService.postImage(
      data?.avatar,
      `${user?.id}-${S3_UPLOAD_TYPE_PRIFIX.AVATAR}`
    )
      .then(async (res) => {
        let form_data = new FormData();
        form_data.append("username", user?.id.toString());
        form_data.append("first_name", data?.firstName);
        form_data.append("last_name", data?.lastName);
        // addSubscriberToMailchimp(user?.email, data?.firstName, data?.lastName);
        if (data?.avatar) {
          form_data.append("avatar", data?.avatar, data?.avatar.name);
        } else {
          const defaultAvatar = await UtilDefaultAvatar.getFile();
          form_data.append("avatar", defaultAvatar, defaultAvatar.name);
        }
        if (data?.id) {
          await BasicProfileService.putBasicProfile(
            {
              photoUrl: res,
              firstName: data?.firstName,
              lastName: data?.lastName,
              title: data?.title,
              workPhone: data?.workPhone,
              countryCode: data?.countryCode,
              // isRecruiter: data?.recruitOnBehalf,
            },
            data?.id
          )
            .then(async (res2) => {
              // form_data.append("secret", user?.chatSecret);
              // await UserChatEngineService.patchUserChatEngine(
              //   user?.chatUserId,
              //   form_data
              // ).catch((e) => dispatch(setErrorMess(e)));
              dispatch(
                setSettingReducer({
                  ...setting,
                  idBasicProfile: res2?.id,
                  firstName: res2?.firstName,
                  lastName: res2?.lastName,
                  title: res2?.title,
                  workPhone: res2?.workPhone,
                  // recruitOnBehalf: res2?.isRecruiter,
                  workPhoneCountryCode: res2?.countryCode,
                  photoUrl: res2?.photoUrl,
                })
              );
              // if (res2?.isRecruiter) {
              // dispatch(getSetting());
              //   dispatch(push(routes.employer.listing.dashboard));
              // } else {
                if (setting?.idOrganisation) {
                  dispatch(push(routes.employer.admin.dashboard));
                } else {
                  dispatch(push(routes.employer.newMember.organization));
                }
              // }
            })
            .catch((e) => dispatch(setErrorMess(e)));
        } else {
          await BasicProfileService.postBasicProfile({
            photoUrl: res,
            firstName: data?.firstName,
            lastName: data?.lastName,
            title: data?.title,
            workPhone: data?.workPhone,
            countryCode: "+64",
            // isRecruiter: data?.recruitOnBehalf,
          })
            .then(async (res3) => {
              const chatSecret = password.randomPassword({
                characters: [password.lower, password.upper, password.digits],
              });
              form_data.append("secret", chatSecret);
              // await UserChatEngineService.postUserChatEngine(form_data)
              //   .then(async (chatEngineRes) => {
              //     await UserService.putUser(user?.id, {
              //       ...user,
              //       chatUserId: chatEngineRes?.id,
              //       chatSecret: chatSecret,
              //     })
              //       .then(() => {
              //         dispatch(
              //           setUserReducer({
              //             ...user,
              //             chatUserId: chatEngineRes?.id,
              //             chatSecret: chatSecret,
              //           })
              //         );
              //       })
              //       .catch((e) => dispatch(setErrorMess(e)));
              //   })
              //   .catch((e) => dispatch(setErrorMess(e)));
              dispatch(
                setSettingReducer({
                  ...setting,
                  idBasicProfile: res3?.id,
                  firstName: res3?.firstName,
                  lastName: res3?.lastName,
                  title: res3?.title,
                  workPhone: res3?.workPhone,
                  // recruitOnBehalf: res3?.isRecruiter,
                  workPhoneCountryCode: res3?.countryCode,
                  photoUrl: res3?.photoUrl,
                })
              );
              // if (res3?.isRecruiter) {
              //dispatch(getSetting());
              //   dispatch(push(routes.employer.listing.dashboard));
              // } else {
              
                if (setting.idOrganisation) {
                  dispatch(push(routes.employer.admin.dashboard));
                } else {
                  dispatch(push(routes.employer.newMember.organization));
                }
              
              // }
            })
            .catch((e) => dispatch(setErrorMess(e)));
        }
      })
      .catch((e) => dispatch(setErrorMess(e)))
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <Card className={classes.card}>
      <CardHeader>
        <p className={classes.title}>Profile</p>
      </CardHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.content}>
          <Controller
            name="avatar"
            control={control}
            render={({ field }) => (
              <ImageUpload
                avatar
                value={field.value}
                onChange={(file) => field.onChange(file)}
                isDisabled={false}
              />
            )}
          />
          <CustomInput
            placeholder="First name"
            type="text"
            autoComplete="off"
            inputRef={register("firstName")}
            errorMessage={errors.firstName?.message}
          />
          <CustomInput
            placeholder="Last name"
            type="text"
            autoComplete="off"
            inputRef={register("lastName")}
            errorMessage={errors.lastName?.message}
          />
          <CustomInput
            placeholder="Title"
            type="text"
            autoComplete="off"
            inputRef={register("title")}
            errorMessage={errors.title?.message}
          />
          <CustomInput
            placeholder="Working phone number"
            type="text"
            autoComplete="off"
            inputRef={register("workPhone")}
            errorMessage={errors.workPhone?.message}
          />
          <>
              {/* <CustomCheckboxFlip
                  firstLabel='ATS'
                  secondLabel='CoPilot'
                  onChange={handleCheckboxChange}
                  checked={isAssist}
              /> */}
              <br></br>

              {/* <h6 style={{ color: 'black' }}>
                  {isAssist ? "Source and Maintain Talent" : "Applicant Tracking System"}
              </h6>
              
              <p style={{ color: 'black' }}>
                  {isAssist 
                      ? "CoPilot is our pay as you go pricing model, you will only be charged per listing that has shortlisted candidates."
                      : "Use the full functionality of Necta's ATS and pay only based on yearly listings at a discounted rate."
                  }
              </p> */}
          </>


        </div>
        <Row className="justify-content-end p-0 m-0">
          {/* <Button
            size="md"
            color="default"
            type="button"
            className={classes.btn}
            onClick={handleBack}
          >
            Back
          </Button> */}
          <Button
            size="md"
            color="primary"
            type="submit"
            className={classes.btn}
          >
           {setting?.idOrganisation ? "Finish" : "Next"}
          </Button>
        </Row>
      </Form>
    </Card>
  );
});

export default BasicProfile;
